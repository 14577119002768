.bottom-menu {
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  max-width: 85px;
  text-align: center;
}

.item-menu-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9166d2 !important;
}

.list-itens-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-sponsors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: 20px 0;
  background-color: white;
  padding: 30px;
}

.img-sponsors {
  width: 100%;
}

.div-icons-sponsors {
  display: flex;
  justify-content: center;
}

.div-padding {
  width: 100%;
  padding: 5%;
  margin-bottom: 10px;
}

.search {
  width: 100%;
  border-radius: 10px;
  background-color: #f1f3f5;
  border: none;
  height: 50px;
  padding: 0 10px;
}

.title-contact {
  width: 25px;
  height: 25px;
  background-color: #9166d2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.div-contact {
  display: flex;
  background-color: white;
  padding: 15px 5px;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
}

.img-contact {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 8px;
}

.section-align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.visit-btn {
  border: none;
  padding: 20px 30px;
  font-size: 20px;
  background-color: black;
  color: white;
}

.center-img {
  height: 100vh;
  display: flex;
  align-items: center;
}

.limit {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.menu-top {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 1;
}

.option-menu-top {
  font-weight: bolder;
  color: black;
  width: 33.33%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-menu-top-selected {
  background-color: #9166d2;
  color: white !important;
}

.option-menu-top:hover {
  color: black;
}

.option-schedule {
  width: 100%;
  background-color: white;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  border-radius: 8px;
}

.img-option-schedule {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.data-option-schedule {
  display: flex;
  flex-direction: column;
}

.add-option-schedule {
  font-size: 30px;
}

.row-options {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-around;
}

.option-row-options {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.option-row-options-selected {
  background-color: #9166d2;
  color: white;
}

.option-row-options-selected:hover {
  color: white;
}

/* --------- */

.menu-home-mobile {
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  position: fixed;
  background-color: white;
  transition: all 0.5s ease-in-out;
  overflow: auto;
}

.close-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  margin: 5px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  z-index: 2;
}

.img-modal {
  width: 100%;
  max-height: 400px;
}

.div-title {
  width: 100%;
  border-bottom: 2px solid #f1f3f5;
  padding-bottom: 10px;
  margin: 10px 0;
}

.add-schedule {
  background-color: green;
  color: white;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  border-radius: 8px;
}

.add-schedule:hover {
  color: white;
}

.div-description-schedule {
  width: 100%;
  background-color: #f1f3f5;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
  margin: 20px 0;
}

.data-description-schedule {
  display: flex;
  flex-direction: column;
}

.read-more {
  color: black;
  border: 1px solid green;
  padding: 8px 20px;
  border-radius: 20px;
}

.btn-map-exhibitors {
  width: 100%;
  border: none;
  margin-top: 10px;
  padding: 10px;
  background-color: green;
  color: white;
  border-radius: 8px;
}

.img-speackers {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.accordion-item {
  border-color: rgba(140, 130, 115, 0.13);
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.social-media {
  text-align: center;
}
.social-media p {
  position: relative;
  top: -10;
  font-size: 12px;
}
.social-media i {
  font-size: 60px;
}

.img-map {
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.img-map-horizontal {
  transform: rotate(90deg); /* Equal to rotateZ(45deg) */
}

.img-logo-store{
  width: 80px;
}

.spinnerloading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
}