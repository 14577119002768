.menu-home-mobile {
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  position: fixed;
  background-color: white;
  transition: all 0.5s ease-in-out;
  overflow: auto;
}

.close-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  margin: 5px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.img-modal{
  width: 100%;
  max-height: 400px;
}

.div-title{
  width: 100%;
  border-bottom: 2px solid #f1f3f5;
  padding-bottom: 10px;
  margin: 10px 0;
  text-align: center;
  font-size: 18px;
}
